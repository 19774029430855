const SectionSection = () => {
  return (
    <section className="self-stretch bg-goldenrod flex flex-row items-start justify-start py-0 pr-0 pl-[41px] box-border gap-[41px] mix-blend-normal max-w-full text-left text-sm-7 text-black font-inter lg:flex-wrap lg:pl-5 lg:pr-5 lg:box-border mq750:gap-[20px]">
      <div className="h-5 hidden flex-row items-center justify-start gap-[8px] mix-blend-normal">
        <img
          className="self-stretch w-[30px] relative max-h-full overflow-hidden shrink-0 mix-blend-normal min-h-[20px]"
          alt=""
          src="/image-section01labelimg.svg"
        />
        <div className="self-stretch flex-1 relative leading-[20px] flex items-center">
          OUR VISION
        </div>
      </div>
      <div className="w-[638px] flex flex-col items-start justify-start pt-[222px]  mq750:pt-[100px] px-0 pb-0 box-border min-w-[638px] max-w-full text-85xl font-libre-baskerville lg:flex-1 mq750:min-w-full mq450:pt-16 mq450:box-border">
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] max-w-full">
          <h1 className="m-0 relative text-inherit tracking-[-1px] leading-[110%] inline-block max-w-full font-inherit mq1050:text-33xl mq1050:leading-[69px] mq450:text-12xl mq450:leading-[46px]">
            <span>{`Our `}</span>
            <i>Vision</i>
          </h1>
          <div className="self-stretch relative text-lg leading-[24px] font-light font-inter">{`We want to take off your mental load! Our vision at Closetrove is to create a digital wardrobe of everything you own making it incredibly easy to manage and plan your look. Our AI powered style recommendations are personalised for your needs and are based off everything you already own. `}</div>
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-col items-center justify-center py-5 pr-5 pl-0 box-border min-w-[468px] max-w-full mq750:min-w-full">
        <img
          className="self-stretch h-[626px] mq450:h-[328px] relative rounded-7xl max-w-full overflow-hidden object-cover"
          loading="lazy"
          alt=""
          src="/freedomconceptwithwomanholdingclothnature-1@2x.png"
        />
      </div>
    </section>
  );
};

export default SectionSection;
