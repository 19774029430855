const SectionSecuritysectionTemp = () => {
  return (
    <section className="self-stretch bg-lightgoldenrodyellow flex flex-col items-start justify-center py-[75px] pr-5 pl-[100px] box-border gap-[75px] mix-blend-normal max-w-full text-left text-33xl text-black font-libre-baskerville lg:pl-[50px] lg:box-border mq750:gap-[37px] mq750:pl-[25px] mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq450:gap-[19px]">
      <h1 className="m-0 w-[1240px] relative text-inherit tracking-[-1px] leading-[110%] inline-block max-w-full font-inherit mq1050:text-33xl mq1050:leading-[46px] mq450:text-12xl mq450:leading-[34px]">
        <span>{`Contact `}</span>
        <i>Us</i>
      </h1>
      <h1 className="m-0 relative tracking-[-1px] leading-[142px] text-90xl inline-block italic font-normal font-inherit whitespace-nowrap max-w-full mq1050:text-60xl mq1050:leading-[70px] mq750:text-23xl mq750:leading-[55px] mq450:text-5xl mq450:leading-[57px] ">
        <a
          href="mailto:hello@closetrove.com"
          className="text-black no-underline"
        >
          hello@closetrove.com
        </a>
      </h1>

      <div className="w-[213px] mq750:w-full flex flex-row items-center justify-between gap-[20px] text-sm font-inter">
        <div className="flex flex-row items-center justify-start py-3 px-0 mix-blend-normal">
          <div className="relative leading-[20px] uppercase font-medium inline-block min-w-[65px]">
            <a
              href="https://www.linkedin.com/company/closetrove"
              className="text-black no-underline"
              target="_blank"
            >
              LinkedIN
            </a>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start pt-3 px-0 pb-2.5 mix-blend-normal border-b-[1px] hover:border-black">
          <div className="relative leading-[20px] uppercase font-medium inline-block min-w-[83px]">
            <a
              href="https://www.instagram.com/theclosetrove"
              className="text-black no-underline"
              target="_blank"
            >
              instagram
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSecuritysectionTemp;
