import { GoPlus } from "react-icons/go";
const FrameComponent1 = () => {
  return (
    <div
      className="self-stretch flex flex-col items-start justify-start gap-[100px] max-w-full shrink-0 text-left text-33xl text-black font-libre-baskerville mq750:gap-[50px] mq450:gap-[25px]"
      id="aboutUs"
    >
      <div className="self-stretch h-[732px] rounded-7xl bg-white overflow-hidden shrink-0 flex flex-col items-start justify-start pt-0 px-3 pb-[86.6px] box-border relative gap-[100px] mix-blend-normal max-w-full lg:h-auto mq750:gap-[50px] mq750:pb-14 mq750:box-border mq450:gap-[25px]">
        <img
          className="w-20 h-20 absolute !m-[0] right-[32px] bottom-[-34.4px] rounded-sm mix-blend-normal"
          alt=""
          src="/rectangle-346240771.svg"
        />
        <div className="mt-[-20.59999999999991px] flex flex-col items-end justify-start gap-[100px] max-w-full shrink-0 mq750:gap-[50px] mq450:gap-[25px]">
          <div className="w-[1168px] flex flex-col items-start justify-start gap-[10.6px] max-w-full mq750:gap-[20px]">
            <div className="self-stretch flex flex-row items-start justify-end">
              <img
                className="h-20 w-20 relative"
                alt=""
                src="/rectangle-346240775.svg"
              />
            </div>
            <div className="w-[1128px] flex flex-row items-start justify-start relative max-w-full">
              <h1 className="m-0 flex-1 relative text-inherit tracking-[-1px] leading-[110%] inline-block max-w-full font-inherit mq1050:text-23xl mq1050:leading-[46px] mq450:text-12xl mq450:leading-[30px]">
                <span>{`About `}</span>
                <i>Us</i>
              </h1>
              <img
                className="h-20 w-20 absolute !m-[0] bottom-[-30.4px] left-[409px] z-[1]"
                alt=""
                src="/rectangle-346240775.svg"
              />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start mq450:pr-2 mq450:pl-2 mq450:pb-4 pl-0 box-border max-w-full text-19xl text-gray-300 font-inter">
            <div className="w-[1216px] flex flex-row flex-wrap items-end justify-start gap-[8px] max-w-full">
              <img
                className="h-20 w-20 relative rounded-sm mix-blend-normal"
                alt=""
                src="/rectangle-346240771.svg"
              />
              <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[13.4px] box-border min-w-[733px] max-w-full mq1050:min-w-full">
                <div className="self-stretch h-[375px] relative">
                  <img
                    className="absolute top-[257px] left-[474.6px] w-[28.8px] h-[28.8px]"
                    alt=""
                    src="/rectangle-346240776.svg"
                  />
                  <h1 className="m-0 absolute top-[0px] left-[0px] mq450:pb-4 text-inherit tracking-[-2.1px] leading-[46.8px] font-light font-inherit flex items-center w-full h-full z-[1] mq1050:text-11xl mq1050:leading-[37px] mq750:text-5xl mq750:leading-[30px] mq450:text-xl mq450:leading-[22px]">
                    <span className="w-full">
                      <span>
                        Trust us when we say this, we have been there.
                      </span>
                      <span className="text-darkslategray-200">
                        {" "}
                        The heaps and piles of laundry waiting to be arranged
                        and the everyday struggle to decide what to wear takes
                        up more brain cells than we'd like. Who are we? We are
                        Ankita and Jaishree - friends since decades; co-founders
                        of Closetrove for a little while and always curious
                        about ways to make everyday chores simpler. We have held
                        this idea very close to us, and finally we are here to
                        share your mental load. Hit us up at
                        hello@closetrove.com and let us know how you feel about
                        the product.
                      </span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="self-stretch flex flex-row items-start justify-start py-0 px-5 box-border max-w-full lg:pl-[22px] lg:pr-[22px] lg:box-border"
        id="faq"
      >
        <div className="flex-1 flex flex-row items-start justify-start gap-[149px] mix-blend-normal max-w-full lg:flex-wrap lg:gap-[74px] mq750:gap-[37px] mq450:gap-[19px]">
          <h1 className="m-0 relative text-inherit tracking-[-1px] leading-[110%] inline-block min-w-[448px] max-w-full font-inherit lg:flex-1 mq750:min-w-full mq1050:text-23xl mq1050:leading-[46px] mq450:text-12xl mq450:leading-[34px]">
            <p className="m-0">Frequently Asked</p>
            <p className="m-0">
              <i>Questions</i>
            </p>
          </h1>
          <div className="flex-1 flex flex-col items-start justify-start gap-[50px] min-w-[418px] max-w-full font-inter mq750:gap-[25px] mq750:min-w-full">
            <div class="max-w-screen-xl mx-auto mq1050:mx-0 px-5 mq750:px-0 bg-[#F3F5F8] min-h-sceen">
              <div class="grid divide-y divide-neutral-200 max-w-xl mx-auto">
                <div class="py-5">
                  <details class="group">
                    <summary class="flex justify-start items-center font-medium text-5xl mq450:text-xl cursor-pointer list-none">
                      <span class="transition pr-4 group-open:rotate-45">
                        <GoPlus />
                      </span>
                      <span> What is Closetrove and how does it work?</span>
                    </summary>
                    <p class="text-[#333333] pl-10 text-xl mq450:text-sm mt-3 group-open:animate-fadeIn">
                      Closetrove is a mobile application designed to help you
                      digitize your wardrobe. It allows you to upload pictures
                      of your clothes, organize them into capsules, create
                      outfits, plan your attire for events, and even track what
                      you wear day-to-day.
                    </p>
                  </details>
                </div>
                <div class="py-5">
                  <details class="group">
                    <summary class="flex justify-start items-center  text-5xl mq450:text-xl font-medium cursor-pointer list-none">
                      <span class="transition pr-4 group-open:rotate-45">
                        <GoPlus />
                      </span>
                      <span> Which platforms is Closetrove available on?</span>
                    </summary>
                    <p class="text-[#333333] pl-10 mt-3 text-xl mq450:text-sm group-open:animate-fadeIn">
                      Closetrove is a mobile application designed to help you
                      digitize your wardrobe. It allows you to upload pictures
                      of your clothes, organize them into capsules, create
                      outfits, plan your attire for events, and even track what
                      you wear day-to-day.
                    </p>
                  </details>
                </div>
                <div class="py-5">
                  <details class="group">
                    <summary class="flex justify-start items-center  text-5xl mq450:text-xl font-medium cursor-pointer list-none">
                      <span class="transition pr-4 group-open:rotate-45">
                        <GoPlus />
                      </span>
                      <span> How do I add items to my digital wardrobe?</span>
                    </summary>
                    <p class="text-[#333333] pl-10 mt-3 mq450:text-sm text-xl group-open:animate-fadeIn">
                      Closetrove is a mobile application designed to help you
                      digitize your wardrobe. It allows you to upload pictures
                      of your clothes, organize them into capsules, create
                      outfits, plan your attire for events, and even track what
                      you wear day-to-day.
                    </p>
                  </details>
                </div>
                <div class="py-5">
                  <details class="group">
                    <summary class="flex justify-start items-center  text-5xl mq450:text-xl  font-medium cursor-pointer list-none">
                      <span class="transition pr-4 group-open:rotate-45">
                        <GoPlus />
                      </span>
                      <span> How can I know more about the app?</span>
                    </summary>
                    <p class="text-[#333333] pl-10 mt-3 mq450:text-sm text-xl group-open:animate-fadeIn">
                      Closetrove is a mobile application designed to help you
                      digitize your wardrobe. It allows you to upload pictures
                      of your clothes, organize them into capsules, create
                      outfits, plan your attire for events, and even track what
                      you wear day-to-day.
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
