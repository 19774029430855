const Frame = () => {
  return (
    <>
      <div
        className="self-stretch text-5xl flex flex-row items-center justify-center py-0  mq750:box-border  mq450:box-border"
        id="howWork"
      >
        <h1 className="m-0 relative tracking-[-1px] leading-[110%] font-inherit mq1050:text-23xl mq1050:leading-[46px] mq450:text-12xl mq450:leading-[34px]">
          <span>{`How it `}</span>
          <i>Works ?</i>
        </h1>
      </div>
      <div className="w-full relative bg-whitesmoke-100 flex flex-col items-center justify-center gap-[140px] text-left text-5xl text-black font-inter lg:flex-col lg:items-center lg:justify-start">
        <div className="w-[1071px] flex flex-row items-start justify-start gap-[50px] mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
          <img
            className="w-[332.9px] relative rounded-7xl h-[480.8px] object-cover mix-blend-normal"
            alt=""
            src="/div-containercarousel@2x.png"
          />
          <div className="h-[481px] mq1050:h-[200px] mq450:w-[270px] mq450:h-[280px] flex flex-col items-start mq450:items-center justify-start mq1050:py-0 py-[153px] mq750:py-0 box-border relative gap-[26px] mix-blend-normal">
            <div className="relative tracking-[-1px] mq450:w-[270px] mq450:text-xl leading-[110%] z-[0] font-libre-baskerville">
              <span>{`Digital `}</span>
              <i>Wardrobe</i>
              <span> Management</span>
            </div>
            <div className="w-[511px] mq450:w-[270px]  relative text-lg leading-[24px] text-darkslategray text-justify flex items-center z-[1]">
              <span className="w-full">
                <p className="m-0">
                  Digitising your closet with Closetrove is the first step to
                  unlock the potential your own wardrobe holds.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Get a single pane view of what you own and what you wear, use
                  the app to check what's in your closet and shop smarter by
                  identifying the patterns. Make the most of your closet by
                  creating new outfits on the go!
                </p>
              </span>
            </div>
            <div className="w-[57px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
              <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
                01/05
              </div>
            </div>
          </div>
        </div>
        <div className="w-[926px] rounded-7xl flex flex-row items-center justify-end gap-[50px] mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
          <img
            className="w-[332.9px] mq450:w-[270px]  relative rounded-7xl h-[480.8px] overflow-hidden shrink-0 object-cover mix-blend-normal"
            alt=""
            src="/div-containercarousel-1@2x.png"
          />
          <div
            className="w-[486px] h-[481px] mq450:w-[270px] mq450:h-[280px]  mq1050:h-[200px] mq450:h-[250px]  flex flex-col items-start justify-start
         py-[153px] mq1050:py-0 box-border relative gap-[26px] mix-blend-normal"
          >
            <div className="relative tracking-[-1px] mq450:text-xl leading-[110%] z-[0] font-libre-baskerville">
              <i>{`Professional Closet `}</i>
              <span>Organization</span>
            </div>
            <div className="w-[454px] mq450:w-[270px] relative text-lg leading-[24px] text-darkslategray text-justify flex items-center z-[1]">
              <span className="w-full">
                <p className="m-0">
                  We get it, it is not easy at times to take the first step,
                  that is why we offer you the services of professional
                  organisers, who would not only digitise your closet but also
                  organise it so well, that you won't have it any other way.
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Reduce your mental (and physical) load by booking our services
                  and declutter your precious space.
                </p>
              </span>
            </div>
            <div className="w-[57px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
              <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
                01/05
              </div>
            </div>
          </div>
        </div>
        <div className="w-[1071px] flex flex-row items-start justify-start gap-[50px]  mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
          <div className="mq450:w-[300.9px] w-[330px] relative rounded-7xl bg-palegreen h-[480.8px] mix-blend-normal">
            <img
              className="absolute top-[11.2px] left-[-80.5px] w-[492px] mq450:w-[450px] h-[480.8px] object-cover"
              alt=""
              src="/image-1@2x.png"
            />
          </div>
          <div className="flex mq1050:h-[200px] mq450:w-[270px] flex-col items-start mq450:items-center justify-start py-[153px] mq1050:py-0 relative gap-[26px] mix-blend-normal">
            <div className="relative tracking-[-1px] mq450:text-xl leading-[110%] z-[0] font-libre-baskerville">
              <span>{`Outfit `}</span>
              <i>Creation</i>
              <span>{` and `}</span>
              <i>Planning</i>
            </div>
            <div className="w-[511px] relative text-lg mq450:w-[270px]  leading-[24px] text-darkslategray text-justify flex items-center z-[1]">{`Plan your outfits of the day, every day, customised for your weather and activities. You can plan outfits, create moodboards and wishlishts. You can even create a capsule closet for that upcoming vacation! Record & shuffle your favorite outfits. The options are endless!`}</div>
            <div className="w-[57px] mq450:w-[50px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
              <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
                01/05
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-[905px] rounded-7xl h-[481px] mq1050:h-[200px] flex flex-row items-center justify-end gap-[50px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
        <img
          className="w-[332.9px] relative rounded-7xl h-[480.8px] overflow-hidden shrink-0 object-cover mix-blend-normal"
          alt=""
          src="/div-containercarousel@2x.png"
        />
        <div className="w-[486px] h-[540px] flex flex-col items-start justify-start py-[153px] mq750:py-0 box-border relative gap-[26px] mix-blend-normal">
          <div className="relative tracking-[-1px] leading-[110%] z-[0] font-libre-baskerville">
            <p className="m-0">
              <i className="font-libre-baskerville">Create listing</i>
              <span> of items</span>
            </p>
            <p className="m-0">
              <span>{`you want to `}</span>
              <i className="font-libre-baskerville">resell</i>
            </p>
          </div>
          <div className="w-[454px] relative text-lg leading-[24px] text-darkslategray text-justify flex items-center z-[1]">
            <span className="w-full">
              <p className="m-0">{`Do you want to get rid of those valuable trousers that no longer fit? Or the dress that just doesn't match with your current vibe? Do you end up pushing them to the 'back stack'? `}</p>
              <p className="m-0">&nbsp;</p>
              <p className="m-0">
                Say no more, we may have found the perfect solution! With all
                your clothes already digitized, you can create a resale listing
                within seconds. The buyers get access to the beautiful pre-loved
                items.
              </p>
            </span>
          </div>
          <div className="w-[57px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
            <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
              01/05
            </div>
          </div>
        </div>
      </div> */}
        <div className="w-[926px] rounded-7xl flex flex-row items-center justify-end gap-[50px] mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
          <img
            className="w-[332.9px] mq450:w-[270px]  relative rounded-7xl h-[480.8px] overflow-hidden shrink-0 object-cover mix-blend-normal"
            alt=""
            src="/div-containercarousel-2@2x.png"
          />
          <div
            className="w-[486px] h-[481px] mq1050:h-[200px] mq450:h-[330px] mq450:w-[270px]  flex flex-col items-start mq450:items-center justify-center mq1050:justify-start
         py-[153px] mq1050:py-0 box-border relative gap-[26px] mix-blend-normal"
          >
            <div className="relative mq450:w-[270px] mq450:text-xl item-start justify-start tracking-[-1px] leading-[110%] z-[0] font-libre-baskerville">
              <i>{`Create listing `}</i>
              <span> of items</span>
              <p className="m-0">
                <span>{`you want to `}</span>
                <i className="font-libre-baskerville">resell</i>
              </p>
            </div>
            <div className="w-[454px] mq450:w-[270px] relative text-lg leading-[24px] text-darkslategray text-justify flex items-center">
              <span className="w-full">
                <p className="m-0">
                  Do you want to get rid of those valuable trousers that no
                  longer fit? Or the dress that just doesn't match with your
                  current vibe? Do you end up pushing them to the 'back stack'?
                </p>
                <p className="m-0">&nbsp;</p>
                <p className="m-0">
                  Say no more, we may have found the perfect solution! With all
                  your clothes already digitized, you can create a resale
                  listing within seconds. The buyers get access to the beautiful
                  pre-loved items.
                </p>
              </span>
            </div>
            <div className="w-[57px] mq450:w-[50px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
              <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
                01/05
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-[1071px] flex flex-row items-start justify-start gap-[50px] mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
        <img
          className="w-[332.9px] relative rounded-7xl h-[480.8px] overflow-hidden shrink-0 object-cover mix-blend-normal"
          alt=""
          src="/div-containercarousel@2x.png"
        />
        <div className="flex flex-col mq750:h-[200px] items-start justify-start py-[153px] mq750:py-0 relative gap-[26px] mix-blend-normal">
          <div className="relative tracking-[-1px] leading-[110%] z-[0] font-libre-baskerville">
            <i>Consult</i>
            <span>{` with a `}</span>
            <i>Stylist</i>
          </div>
          <div className="w-[511px] relative text-lg leading-[24px] text-darkslategray text-justify flex items-center z-[1]">{`Our aim is to make personal styling accessible. Our style expert are here to give you personalised tips and recommendations from things you already own. They suggest you to buy more items only when you ask them to. We integrate with a strong generative AI to give you personalised style recommendations, all on your fingertips. `}</div>
          <div className="w-[57px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
            <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
              01/05
            </div>
          </div>
        </div>
      </div> */}
        <div className="w-[1071px] flex flex-row items-start justify-start gap-[50px] mq1050:gap[25px] mix-blend-normal mq1050:flex-col mq1050:items-center mq1050:justify-center">
          <img
            className="w-[332.9px] mq450:w-[270px] relative rounded-7xl h-[480.8px] object-cover mix-blend-normal"
            alt=""
            src="/div-containercarousel-3@2x.png"
          />
          <div className="h-[481px] mq1050:h-[200px] mq450:h-[250px] flex flex-col items-start justify-start mq1050:py-0 py-[153px] mq750:py-0 box-border relative gap-[26px] mix-blend-normal">
            <div className="relative tracking-[-1px] mq450:text-xl leading-[110%] z-[0] font-libre-baskerville">
              <i>Consult</i>
              <span>{` with a `}</span>
              <i>Stylist</i>
            </div>
            <div className="w-[511px] mq450:w-[270px] relative text-lg leading-[24px] text-darkslategray text-justify flex items-center">
              <span className="w-full">
                <p className="m-0">
                  Our aim is to make personal styling accessible. Our style
                  expert are here to give you personalised tips and
                  recommendations from things you already own. They suggest you
                  to buy more items only when you ask them to. We integrate with
                  a strong generative AI to give you personalised style
                  recommendations, all on your fingertips.
                </p>
              </span>
            </div>
            <div className="w-[57px] mq450:w-[50px] absolute !m-[0] top-[0px] right-[0.4px] rounded-xl [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.04),_rgba(0,_0,_0,_0.04))] h-9 mix-blend-normal z-[2] text-4xs-9 text-gray">
              <div className="absolute top-[8px] left-[calc(50%_-_11.5px)] leading-[20px]">
                01/05
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default Frame;
