import FrameComponent from "../components/FrameComponent";
import FrameComponent2 from "../components/FrameComponent2";
import SectionSection from "../components/SectionSection";
import FrameComponent3 from "../components/FrameComponent3";
import InputCluster from "../components/InputCluster";
import ValueProcessor from "../components/ValueProcessor";
import LogicGate from "../components/LogicGate";
import FunctionDefiner from "../components/FunctionDefiner";
import Frame from "../components/Frame";
import FrameComponent1 from "../components/FrameComponent1";
import SectionSecuritysectionTemp from "../components/SectionSecuritysectionTemp";

const Nd = () => {
  return (
    <div className="w-full relative rounded bg-whitesmoke-100 overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <section className="self-stretch bg-white overflow-hidden flex flex-row items-start justify-start max-w-full">
        <div className="flex-1 bg-lightgoldenrodyellow flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border gap-[100px] lg:gap-[120px] mix-blend-normal max-w-full mq1050:gap-[80px] mq750:gap-[80px] mq450:gap-[80px]">
          <FrameComponent />
          <FrameComponent2 />
        </div>
      </section>
      <SectionSection />
      <FrameComponent3 />

      <Frame />

      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-14 pb-[100px] box-border max-w-full lg:pb-[65px] lg:box-border mq750:pl-7 mq750:pr-7 mq750:pb-[27px] mq750:box-border mq1050:pb-[42px] mq1050:box-border">
        <div className="flex-1 flex flex-col items-start justify-start gap-[121.2px] max-w-full lg:gap-[61px] mq750:gap-[30px] mq450:gap-[15px]">
          <FrameComponent1 />
        </div>
      </section>
      <SectionSecuritysectionTemp />
    </div>
  );
};

export default Nd;
