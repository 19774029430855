const FrameComponent3 = () => {
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[100px] box-border max-w-full text-left text-85xl text-black font-libre-baskerville mq750:pb-[65px] mq750:box-border">
      <div className="flex-1 [background:linear-gradient(180deg,_#c0aafe,_#f7cdfe)] overflow-hidden flex flex-col items-center justify-start py-[150px] px-5 box-border gap-[20px] mix-blend-normal max-w-full mq750:pt-[97px] mq750:pb-[97px] mq750:box-border">
        <div className="w-[692px] pb-10 sm:pb-0 flex flex-col items-start justify-start max-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <h1 className="m-0 pb-10 sm:pb-2 relative text-inherit tracking-[-1px] leading-[110%] font-inherit mq1050:text-33xl mq1050:leading-[69px] mq450:text-12xl mq450:leading-[46px]">
              <span>{`Our `}</span>
              <i>Approach</i>
            </h1>
            <div className="self-stretch h-[60px] relative text-lg leading-[24px] font-inter text-center mq450:text-start flex items-center justify-center shrink-0">
              We not only organise your wardrobe, we create a digital version of
              it so you can have everything on your fingertips.
            </div>
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-3 px-5 bg-[transparent] w-[325px] rounded-81xl [background:linear-gradient(97.56deg,_#472723,_#110302)] flex flex-row items-center justify-center box-border whitespace-nowrap max-w-full">
          <b className="relative text-smi-5 tracking-[-0.14px] leading-[18px] inline-block font-inter text-white text-left min-w-[72px]">
            Get the App
          </b>
        </button>
      </div>
    </section>
  );
};

export default FrameComponent3;
