import { useEffect, useRef, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
const PopUp = ({ showModal, setShowModal }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");
  const emailRef = useRef("");

  const validateEmail = (em) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const trimmedEmail = emailRef.current.trim();
    console.log(trimmedEmail);
    console.log("Validate", validateEmail(trimmedEmail));

    if (validateEmail(trimmedEmail)) {
      try {
        let existingEmailsRes = await fetch(
          "https://closetrove-default-rtdb.firebaseio.com/emails.json"
        );

        if (!existingEmailsRes.ok) {
          throw new Error("Failed to fetch existing emails");
        }

        let existingEmailsData = await existingEmailsRes.json();
        console.log(existingEmailsData);

        if (existingEmailsData) {
          const existingEmails = Object.values(existingEmailsData).map(
            (item) => item.trimmedEmail
          );
          console.log(existingEmails);

          if (existingEmails && existingEmails.includes(trimmedEmail)) {
            setSucess("");
            setError("This email is already in waitlist.");

            return;
          } else {
            let res = await fetch(
              "https://closetrove-default-rtdb.firebaseio.com/emails.json",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },

                body: JSON.stringify({ trimmedEmail }),
              }
            );

            if (res.ok) {
              setSucess("Email added successfully in waitlist!");
              // alert("Email added successfully in waitlist!");
            } else {
              setError("Please enter a valid email address.");
              // alert("Failed to submit email. Please try again later.");
            }
          }
        } else {
          let res = await fetch(
            "https://closetrove-default-rtdb.firebaseio.com/emails.json",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },

              body: JSON.stringify({ trimmedEmail }),
            }
          );

          if (res.ok) {
            setSucess("Email added successfully in waitlist!");
            // alert("Email submitted successfully!");
          } else {
            setError("Please enter a valid email address.");
            // alert("Failed to submit email. Please try again later.");
          }
        }
      } catch (error) {
        console.error("Error submitting email:", error);
        setError("An error occurred. Please try again later.");
        // alert("An error occurred. Please try again later.");
      }
    } else {
      setError("Please enter a valid email address.");
      // alert("Please enter a valid email address.");
    }
  };

  const handleOnchange = (e) => {
    const hasUpperCase = /[A-Z]/.test(e.target.value);
    setSucess("");
    if (hasUpperCase) {
      setError("Please enter the email address in lowercase letters.");
      // alert("Please enter the email address in lowercase letters.");
    } else {
      setError("");
      emailRef.current = e.target.value;
      setEmail(e.target.value);
    }
  };

  return (
    <div className="w-[100%] rounded-[40px] h-[40%] bg-[#D8F1FF] flex flex-col items-center mq450:max-h-[526px] mq450:px-[10px] justify-center py-[12vh] px-[79px] mq750:px-[10px] box-border relative gap-[26px] max-w-full max-h-full overflow-auto text-center text-lgi text-black font-inter">
      <div className="relative text-6xl	mq450:text-11xl leading-[110%] z-[0] text-43xl-4 font-libre-baskerville">
        <i>
          <span className="tracking-[-0.6px] text-transparent !bg-clip-text [background:linear-gradient(97.56deg,_#472723,_#110302)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
            Closetrove
          </span>
          <span className="text-41xl tracking-[-1px] text-gray-100">{` `}</span>
          <span className="text-43xl-4 tracking-[-0.6px] text-transparent !bg-clip-text [background:linear-gradient(97.56deg,_#472723,_#110302)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
            App
          </span>
        </i>
        <span className="text-41xl tracking-[-1px]"> is underway</span>
      </div>
      <div className="relative leading-[20px] font-light text-gray-200 z-[1]">
        Drop your email here and we will keep you posted.
      </div>
      <div className="self-stretch  rounded-81xl [background:linear-gradient(97.56deg,_rgba(71,_39,_35,_0.12),_rgba(17,_3,_2,_0.12))] overflow-hidden mq450:h-[40px]  flex flex-row items-center justify-between p-1.5 z-[2] border-[3px] border-solid border-slate-400">
        <input
          className="[border:none] px-2 w-[68%] text-11xl mq750:text-4xl mq450:text-sm mq750:w-[58%] [outline:none] py-0 bg-[transparent] mq450:h-[40px] relative h-[55px] overflow-hidden shrink-0"
          type="email"
          name="email"
          value={email}
          autoComplete="off"
          onChange={handleOnchange}
          required
        />
        <button
          className="cursor-pointer w-[30%] mq1050:w-[40%] mq750:w-[34%] [border:none] py-1 mq450:py-0 mq750:h-12 mq450:h-10 px-[20px] mq450:px-2 bg-[transparent] rounded-81xl [background:linear-gradient(97.56deg,_#472723,_#110302)] h-14 flex flex-row items-center justify-between box-border"
          onClick={handleSubmit}
        >
          <div className="relative text-[16px] mq750:text-[12px] mq450:py-0 mq1050:text-[14px] leading-[16px] font-libre-baskerville text-white text-center">
            Join The Waitlist
          </div>
          <div className="w-[14.2px] relative text-[15.9px] mq750:hidden leading-[16px] font-font-awesome-5-free text-white text-center flex items-center justify-center h-4 shrink-0 ">
            <FiArrowUpRight />
          </div>
        </button>
      </div>
      <div className="flex flex-col justify-start items-start">
        <div>{error && <div className="text-red-600 ">{error}</div>}</div>
        <div>{sucess && <div className="text-green-600 ">{sucess}</div>}</div>
      </div>

      <div className="w-[79px] !m-[0] absolute top-[19px] right-[30px] flex flex-row items-center justify-between z-[3] text-gray-100">
        <div className="flex items-center  justify-center relative leading-[20px] font-light">
          <div className="pr-2">Close</div>
          <RxCross2
            onClick={() => setShowModal(!showModal)}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default PopUp;
