const FrameComponent2 = () => {
  return (
    <div className="self-stretch flex flex-row items-start justify-start relative max-w-full text-left text-sm text-white font-inter">
      <img
        className="mq450:h-[516px] mq750:h-[516px] flex-1 relative max-w-full overflow-hidden object-cover"
        alt=""
        src="/frame3.png"
      />
    </div>
  );
};

export default FrameComponent2;
