import { Link } from "react-scroll";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import PopUp from "./PopUp";

const FrameComponent = () => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [close, setCloseModal] = useState(false);

  const togglePopup = () => {
    setOpen(!openpop);
  };

  return (
    <>
      <div
        className="self-stretch flex flex-col w-[1440px] items-center justify-centerss py-0 pr-2.5 pl-0 box-border gap-[41px] max-w-full text-center text-85xl text-black font-libre-baskerville mq750:gap-[20px]"
        id="home"
      >
        <header className="max-w-full self-stretch">
          <div className="self-stretch [backdrop-filter:blur(26px)] overflow-hidden flex flex-row items-center justify-between py-[25px] px-10 box-border mix-blend-normal gap-[20px] max-w-full text-left text-xl text-darkslategray-100 font-inter">
            <Link href="/">
              <img
                className="h-[63px] w-[165.6px] cursor-pointer relative overflow-hidden shrink-0 object-contain"
                alt=""
                src="/frame-48095562@2x.png"
              />
            </Link>
            <div className="mq1050:block hidden" onClick={() => setOpen(!open)}>
              <img
                src="/hamburger.png"
                className={`${open ? "hidden" : "block"}`}
                alt=""
              />
              <div className={`${open ? "block" : "hidden"}`}>
                <RxCross2 />
              </div>
            </div>
            <div className="w-[590px] overflow-hidden shrink-0 flex flex-row items-center justify-start px-0 pb-0 box-border gap-[50.7px] max-w-full mq750:gap-[35px] mq1050:hidden mq450:gap-[17px]">
              <div className="relative leading-[20px] uppercase font-medium inline-block min-w-[61px]">
                <Link
                  href="/"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="no-underline cursor-pointer block	text-black"
                >
                  HOME
                </Link>
              </div>
              <div className="relative leading-[20px] uppercase font-medium whitespace-nowrap">
                <Link
                  to="howWork"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={800}
                  className="no-underline cursor-pointer	text-black"
                >
                  How IT WOrks?
                </Link>
              </div>
              <div className="relative leading-[20px] uppercase font-medium inline-block min-w-[104px] whitespace-nowrap hover:text-gray-600">
                <Link
                  to="aboutUs"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={800}
                  className="no-underline cursor-pointer	text-black"
                >
                  About US
                </Link>
              </div>
              <div className="relative leading-[20px] uppercase font-medium inline-block min-w-[52px]">
                <Link
                  to="faq"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={800}
                  className="no-underline cursor-pointer	text-black"
                >
                  FAQs
                </Link>
              </div>
            </div>

            <button
              className="cursor-pointer [border:none] py-3 px-[58.5px] bg-[transparent] rounded-81xl [background:linear-gradient(97.56deg,_#472723,_#110302)] flex flex-row items-start mq1050:hidden mq750:hidden mq450:hidden justify-start whitespace-nowrap"
              onClick={() => setShowModal(!showModal)}
            >
              <b className="relative text-smi-5 tracking-[-0.14px] leading-[18px] inline-block font-inter text-white text-left min-w-[88px]">
                Download App
              </b>
            </button>
          </div>
          <div
            className={`flex flex-col justify-between mq450:text-sm mq750:text-xl text-xl font-inter transition duration-500 ${
              open ? "block h-auto py-2" : "hidden"
            }`}
          >
            <Link
              href={"/"}
              spy={true}
              smooth={true}
              offset={50}
              duration={800}
              className="no-underline cursor-pointer block pb-2	text-black "
            >
              HOME
            </Link>
            <Link
              to="howWork"
              spy={true}
              smooth={true}
              offset={50}
              duration={800}
              className="no-underline cursor-pointer pb-2 block	text-black"
            >
              How IT WOrks?
            </Link>
            <Link
              to="aboutUs"
              spy={true}
              smooth={true}
              offset={50}
              duration={800}
              className="no-underline pb-2 block cursor-pointer	text-black "
            >
              About US
            </Link>
            <Link
              to="faq"
              spy={true}
              smooth={true}
              offset={50}
              duration={800}
              className="no-underline block pb-2 cursor-pointer	text-black "
            >
              FAQs
            </Link>
          </div>
        </header>

        {/* Hero section */}
        <div className="self-stretch flex flex-col items-center justify-start py-0 px-5 box-border gap-[40px] max-w-full">
          <div className="w-[947px] flex flex-row items-start justify-start max-w-full mq750:gap-[100px] mq1050:gap-[151px]">
            <h1 className="m-0 flex-1 relative text-inherit tracking-[-1px] leading-[110%] inline-block max-w-full font-inherit mq1050:text-33xl mq1050:leading-[69px] mq450:text-12xl mq450:leading-[46px]">
              <p className="m-0">Transform your</p>
              <p className="m-0">
                <i className="text-transparent !bg-clip-text [background:linear-gradient(97.56deg,_#472723,_#110302)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                  Closet
                </i>
                <span className="font-libre-baskerville">
                  <span className="text-gray-100">{` `}</span>
                  <span className="text-black">Today</span>
                </span>
              </p>
            </h1>
          </div>
          <div className="w-[947px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-lg text-gray-200 font-inter">
            <div className="relative leading-[20px] font-light">
              Unlock the full potential of your wardrobe with Closetrove - your
              digital closet.
            </div>
          </div>
          <div>
            <button
              className="cursor-pointer [border:none] py-3 px-5 bg-[transparent] w-[325px] mq450:w-[280px] rounded-81xl [background:linear-gradient(97.56deg,_#472723,_#110302)] flex flex-row items-center justify-center hidden mq1050:block mq750:block mq450:block box-border whitespace-nowrap max-w-full"
              onClick={() => setShowModal(!showModal)}
            >
              <b className="relative text-smi-5 tracking-[-0.14px] leading-[18px] inline-block font-inter text-white text-left min-w-[88px]">
                Download App
              </b>
            </button>
            {/* <button className="cursor-pointer [border:none] py-3 w-[100%] px-[58.5px] bg-[transparent] rounded-81xl [background:linear-gradient(97.56deg,_#472723,_#110302)] flex flex-row items-start hidden mq1050:block mq750:block mq450:block justify-start whitespace-nowrap">
            <b className="relative text-smi-5 tracking-[-0.14px] leading-[18px] inline-block font-inter text-white text-left min-w-[88px]">
              <a href="" className="text-[#FFFFFF] no-underline ">
                Download App
              </a>
            </b>
          </button> */}
          </div>
        </div>
      </div>

      {showModal ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
            <div className="w-[70%] justify-center items-center flex flex-col overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <PopUp showModal={showModal} setShowModal={setShowModal} />
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default FrameComponent;
